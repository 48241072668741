import axios from 'axios'

const get = async (cep) => {
  if (process.env.NODE_ENV !== 'production') {
    return { data: null }
  }

  return await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
}

const viaCEP = { get }

export default viaCEP
