import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Grid, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Card } from 'components'

import helpers from 'helpers'

const Responsible = ({ errors, control, isClient = true }) => {
  return (
    <Card title="Preencha as informações do responsável da empresa">
      <Grid container spacing={2}>
        {isClient && (
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography variant="body2">
                Esses dados serão utilizados para criar o responsável pela
                empresa e o usuário de contato para questões relacionadas a
                cobranças de pagamento. No entanto, caso a empresa já exista, a
                alteração desse campo não afetará o responsável previamente
                definido para a empresa.
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.responsibleName}
                helperText={errors?.responsibleName?.message}
                label="Nome do responsável pela empresa"
              />
            }
            control={control}
            name="responsibleName"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.responsibleEmail}
                helperText={errors?.responsibleEmail?.message}
                label="E-mail do responsável pela empresa"
              />
            }
            control={control}
            name="responsibleEmail"
            mode="onBlur"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.responsiblePhone}
                helperText={errors?.responsiblePhone?.message}
                label="Telefone do responsável da empresa"
              />
            }
            onChange={([event]) => {
              return helpers.formatters.phone(event.target.value)
            }}
            control={control}
            name="responsiblePhone"
            mode="onChange"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

Responsible.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isClient: PropTypes.bool,
}

export default Responsible
