import React, { useState } from 'react'

import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

import { useSnackbar } from 'hooks'

import { BaseDialog, LoadingFeedback } from 'components'
import { ListContracts } from './components'

import helpers from 'helpers'
import * as service from 'service'
import schema from './schema'

const ChurnCreateModal = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const handleEdit = (churnId) => {
    history.push(
      reverse(routes.churns.edit, {
        churnId: churnId,
      }),
    )
  }

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      paymentContract: { id: null, name: '' },
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      const response = await service.dponet.churns.create({
        paymentContractId: data?.paymentContract?.id,
      })

      snackbar.open({
        message: 'Churn criado com sucesso!',
        variant: 'success',
      })

      handleEdit(response?.data?.churnId)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar criar o churn',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Criar solicitação de churn"
        closeButtonText="Voltar"
        actionButtonText="Confirmar"
        justifyActions="flex-end"
        formRef="form-churn-create"
      >
        <Box mb={2}>
          <Alert severity="info">
            <Typography variant="body2">
              Para pesquisar por CNPJ/CPF, digite o número sem pontos, traços ou
              barras.
            </Typography>
          </Alert>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} id="form-churn-create">
          <ListContracts control={control} errors={errors} />
        </form>
      </BaseDialog>
    </>
  )
}

ChurnCreateModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default ChurnCreateModal
