import { useState } from 'react'
import { usePermissions } from 'hooks'

import {
  Container,
  ContentHeader,
  LoadingBox,
  Page,
  MenuButton,
  ButtonLogs,
  Permitted,
  LoadingFeedback,
} from 'components'
import { CompanyForm, ActionButtons } from './components'
import PropTypes from 'prop-types'

import constants from 'constants/index'
import useCompaniesCache from 'hooks/caches/useCompaniesCache'

const CompaniesEdit = ({ match }) => {
  const { companyId } = match.params

  const [isLoading, setIsLoading] = useState(false)

  const permissions = usePermissions()
  const companyCache = useCompaniesCache()

  const editionPermitted = permissions.permitted(
    constants.permissions.COMPANIES.UPDATE,
  )

  const hasPermissionToLoadLogs =
    permissions.permitted(constants.permissions.COMPANIES.LIST) ||
    permissions.permitted(constants.permissions.COMPANIES.SHOW_LOGS)

  const refresh = () => {
    companyCache.useUpdateCache(companyId)
  }

  const companyViewRequest = companyCache.useView(companyId)

  return (
    <Page title={editionPermitted ? 'Atualizar empresa' : 'Visualizar empresa'}>
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Empresas"
          title={editionPermitted ? 'Alterar empresa' : 'Visualizar empresa'}
          subtitle={
            editionPermitted
              ? 'Atualizar os dados da empresa'
              : 'Visualizar os dados da empresa'
          }
        >
          <MenuButton>
            <Permitted
              someTags={[
                constants.permissions.COMPANIES.LIST,
                constants.permissions.COMPANIES.SHOW_LOGS,
              ]}
            >
              <ButtonLogs
                entityId={companyId}
                routes="companies"
                disabled={!hasPermissionToLoadLogs}
              />
            </Permitted>
            <ActionButtons
              company={companyViewRequest?.data?.company}
              refresh={refresh}
              setIsloading={setIsLoading}
            />
          </MenuButton>
        </ContentHeader>
        {companyViewRequest.isFetching ? (
          <LoadingBox />
        ) : (
          <CompanyForm
            companyData={companyViewRequest?.data?.company}
            editionMode
            editionPermitted={editionPermitted}
          />
        )}
      </Container>
    </Page>
  )
}

CompaniesEdit.propTypes = {
  match: PropTypes.object,
}

export default CompaniesEdit
