import PropTypes from 'prop-types'
import { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import useFetch from 'hooks/useFetch'

import { LoadingFeedback } from 'components'

import * as service from 'service'

const ListContracts = ({ errors, control }) => {
  const [nameds, setNameds] = useState('')

  const observable = nameds?.length >= 4

  const { response, isLoading } = useFetch(
    service.dponet.paymentContract.lists,
    {
      fullIdentifierCont: nameds,
      byChurn: true,
    },
    [nameds],
    observable,
  )

  const loading = observable && isLoading
  const paymentContracts = response?.data?.paymentContracts || []

  return (
    <>
      <LoadingFeedback open={loading} />
      <Controller
        as={
          <Autocomplete
            options={paymentContracts}
            getOptionLabel={(option) => option?.name}
            noOptionsText={
              !observable && 'Digite pelo menos 4 caracteres para buscar'
            }
            getOptionSelected={(option, value) =>
              !value || option?.id === value?.id || value?.id === 0
            }
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors?.paymentContract}
                helperText={errors?.paymentContract?.message}
                label="Numero do contrato, nome ou CNPJ/CPF do cliente/sublicenciamento"
                variant="outlined"
                onChange={(e) => setNameds(e.target.value)}
              />
            )}
          />
        }
        control={control}
        name="paymentContract"
        mode="onChange"
        onChange={([, data]) => {
          return data
        }}
      />
    </>
  )
}

ListContracts.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
}

export default ListContracts
