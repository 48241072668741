import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import { Select } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import helpers from 'helpers'

const Plans = ({ control, errors, watch, setValue, isEdit }) => {
  const { response: segmentResponse, isLoading: segmentIsLoading } = useFetch(
    service.dponet.segments.get,
    {
      order: 'name ASC',
      active: true,
      perPage: 10000,
      activePlans: true,
      partnership: true,
    },
    [],
  )

  const isSegment = watch('segmentId')

  const { response: plansResponse, isLoading: plansIsLoading } = useFetch(
    service.dponet.plans.get,
    {
      segmentId: watch('segmentId'),
      status: true,
    },
    [isSegment],
    isSegment,
  )

  const watchPlan = watch('planId')

  useEffect(() => {
    if (isEdit) {
      plansResponse?.data?.plans.find((plan) => {
        if (plan?.id === watchPlan) {
          if (!plan?.typeService || !plan?.vertical) return null
          setValue('typeService', plan?.typeService)
          setValue('vertical', plan?.vertical)
        }
      })
    }

    //eslint-disable-next-line
  }, [watchPlan])

  return (
    <>
      <Grid item xs={12}>
        {!segmentIsLoading && (
          <Controller
            control={control}
            name="segmentId"
            mode="onChange"
            as={
              <Select
                label="Segmento da Empresa"
                items={segmentResponse?.data?.segments}
                error={!!errors?.segmentId}
                helperText={errors?.segmentId?.message}
              />
            }
          />
        )}
      </Grid>
      {isSegment && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name="planId"
            mode="onChange"
            as={
              <Select
                label="Selecione o plano"
                items={helpers.plan.option(plansResponse?.data?.plans)}
                startIcon={
                  plansIsLoading && (
                    <CircularProgress color="primary" size={20} />
                  )
                }
                error={!!errors?.planId}
                helperText={errors?.planId?.message}
              />
            }
          />
        </Grid>
      )}
    </>
  )
}

Plans.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  isEdit: PropTypes.bool,
  plan: PropTypes.object,
  isTrial: PropTypes.bool,
}

export default Plans
